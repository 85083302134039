@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css?family=Poppins:wght@400;500;600;700&display=swap');


@layer base {
  ::-webkit-scrollbar {
    @apply w-2 h-2
  }

  ::-webkit-scrollbar-track {
    @apply bg-gray-200 rounded-[5px]
  }
  ::-webkit-scrollbar-thumb {
    @apply bg-gray-400 rounded-[5px]
  }

  ::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-500
  }
}

@layer utilities{
  .line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  
  .line-clamp-3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
  
  .line-clamp-none {
    overflow: visible;
    display: block;
    -webkit-box-orient: horizontal;
    -webkit-line-clamp: none;
  }

  .schedule-table-last-row td {
    border-bottom-width: 0px; /* Change this to your desired border width */
    /* border-bottom-color: #333; Change this to your desired border color */
  }
  
}


.ant-drawer-title {
  color : #ffffff !important ; 
}

.ant-drawer-mask.zIndex { 
  position: relative !important;
 }

 .fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1800ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1800ms;
}

.resize-enter {
  height: 0;
  transform: scaleY(0);
  transform-origin: top;
}

.resize-enter-active {
  height: 100%;
  transform: scaleY(1);
  transition: height 400ms, transform 400ms;
}

.resize-exit {
  height: 100%;
  transform: scaleY(1);
  transform-origin: top;
}

.resize-exit-active {
  height: 0;
  transform: scaleY(0);
  transition: height 400ms, transform 400ms;
}

@media print {
  .pdf-container {
    display: none;
  }
}

.pdf-container {
  margin: 1em 0;
  overflow-y: auto;
}

.pdf-container .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdf-container .react-pdf__Page {
  max-width: calc(100% - 2em);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  margin: 1em;
}

.pdf-container .react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}

.pdf-container .react-pdf__message {
  padding: 20px;
  color: white;
}

.schedule-table { border-collapse: collapse; }

.table-fix-head {
  overflow: auto;
}

.table-fix-head thead th {
  position: sticky;
  top: 0;
}
.ant-switch-inner {
  margin-right: 0px !important;
    margin-left: 0px !important;
}

.schedule-scrollbar::-webkit-scrollbar-track {
  border-radius: 0px 5px 5px 0px !important;
}
.schedule-scrollbar::-webkit-scrollbar-track {
  @apply !bg-gray-100;
}
.schedule-scrollbar::-webkit-scrollbar-thumb {
  @apply !bg-gray-200
}
.schedule-scrollbar::-webkit-scrollbar-thumb:hover {
  @apply !bg-gray-300
} 
